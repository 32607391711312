<template>
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.956 24H15.7626V3.24396H13.8901C10.4615 3.24396 8.66813 4.95824 8.66813 7.51648C8.66813 10.4176 9.90769 11.7626 12.4659 13.4769L14.5758 14.9011L8.50989 24H4L9.45934 15.8769C6.32088 13.6352 4.55385 11.4462 4.55385 7.75385C4.55385 3.13846 7.77143 0 13.8637 0H19.9297L19.956 24Z"
            fill="#FC3F1D"/>
    </svg>
</template>

<script>
export default {}
</script>
