<script setup>
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';
import BitbucketIcon from '@/Components/SocialstreamIcons/BitbucketIcon.vue';
import FacebookIcon from '@/Components/SocialstreamIcons/FacebookIcon.vue';
import GithubIcon from '@/Components/SocialstreamIcons/GithubIcon.vue';
import GitLabIcon from '@/Components/SocialstreamIcons/GitLabIcon.vue';
import GoogleIcon from '@/Components/SocialstreamIcons/GoogleIcon.vue';
import InputError from '@/Components/InputError.vue';
import LinkedInIcon from '@/Components/SocialstreamIcons/LinkedInIcon.vue';
import TwitterIcon from '@/Components/SocialstreamIcons/TwitterIcon.vue';
import {route} from "ziggy-js";
import YandexIcon from "@/Components/SocialstreamIcons/YandexIcon.vue";
import VkIcon from "@/Components/SocialstreamIcons/VkIcon.vue";
import MailruIcon from "@/Components/SocialstreamIcons/MailruIcon.vue";

const error = computed(() => usePage().props.errors.socialstream);
</script>

<template>
    <div class="border-t border-dashed px-2">
        <div class="flex flex-row dark:text-slate-200 items-center justify-center py-4 text-sm">
            <!--      <hr class="w-full mr-2">-->
            {{ $t('or login with') }}
            <!--      <hr class="w-full ml-2">-->
        </div>

        <div class="flex flex-col items-center justify-center">
            <a v-if="$page.props.socialstream.providers.includes('facebook')"
               :href="route('oauth.redirect', 'facebook')">
                <FacebookIcon class="h-6 w-6 mx-2"/>
                <span class="sr-only dark:text-slate-200">Facebook</span>
            </a>
            <a v-if="$page.props.socialstream.providers.includes('google')" :href="route('oauth.redirect', 'google')"
               class="w-full bg-slate-200 dark:bg-slate-800 h-10 flex items-center justify-center rounded-full hover:bg-slate-300 dark:hover:bg-slate-700 transition-colors">
                <div class="w-[90px] whitespace-nowrap">
                    <GoogleIcon class="h-6 w-6 mx-2 inline"/>
                    <span class="dark:text-slate-200">Google</span>
                </div>
            </a>

            <a v-if="$page.props.socialstream.providers.includes('yandex')" :href="route('oauth.redirect', 'yandex')"
               class="w-full bg-slate-200 dark:bg-slate-800 h-10 flex items-center justify-center rounded-full mt-3 hover:bg-slate-300 dark:hover:bg-slate-700 transition-colors">
                <div class="w-[90px] whitespace-nowrap">
                    <YandexIcon class="h-6 w-6 mx-2 inline"/>
                    <span class="dark:text-slate-200">Yandex</span>
                </div>
            </a>
            <a v-if="$page.props.socialstream.providers.includes('vkontakte')"
               :href="route('oauth.redirect', 'vkontakte')"
               class="w-full bg-slate-200 dark:bg-slate-800 h-10 flex items-center justify-center rounded-full mt-3 hover:bg-slate-300 dark:hover:bg-slate-700 transition-colors">
                <div class="w-[90px] whitespace-nowrap">
                    <VkIcon class="h-6 w-6 mx-2 inline"/>
                    <span class="dark:text-slate-200">{{ $t('VK') }}</span>
                </div>
            </a>
            <a v-if="$page.props.socialstream.providers.includes('mailru')" :href="route('oauth.redirect', 'mailru')"
               class="w-full bg-slate-200 dark:bg-slate-800 h-10 flex items-center justify-center rounded-full mt-3 hover:bg-slate-300 dark:hover:bg-slate-700 transition-colors">
                <div class="w-[90px] whitespace-nowrap">
                    <MailruIcon class="h-6 w-6 mx-2 inline"/>
                    <span class="dark:text-slate-200">Mail.ru</span>
                </div>
            </a>

            <a
                v-if="$page.props.socialstream.providers.includes('twitter')"
                :href="route('oauth.redirect', 'twitter')">
                <TwitterIcon class="h-6 w-6 mx-2"/>
                <span class="sr-only">Twitter</span>
            </a>

            <a
                v-if="$page.props.socialstream.providers.includes('twitter-oauth-2')"
                :href="route('oauth.redirect', 'twitter-oauth-2')">
                <TwitterIcon class="h-6 w-6 mx-2"/>
                <span class="sr-only">Twitter</span>
            </a>

            <a v-if="$page.props.socialstream.providers.includes('linkedin')"
               :href="route('oauth.redirect', 'linkedin')">
                <LinkedInIcon class="h-6 w-6 mx-2"/>
                <span class="sr-only">LinkedIn</span>
            </a>

            <a v-if="$page.props.socialstream.providers.includes('github')" :href="route('oauth.redirect', 'github')">
                <GithubIcon class="h-6 w-6 mx-2"/>
                <span class="sr-only">GitHub</span>
            </a>

            <a v-if="$page.props.socialstream.providers.includes('gitlab')" :href="route('oauth.redirect', 'gitlab')">
                <GitLabIcon class="h-6 w-6 mx-2"/>
                <span class="sr-only">GitLab</span>
            </a>

            <a v-if="$page.props.socialstream.providers.includes('bitbucket')"
               :href="route('oauth.redirect', 'bitbucket')">
                <BitbucketIcon class="h-6 w-6 mx-2"/>
                <span class="sr-only">BitBucket</span>
            </a>
        </div>

        <InputError :message="error" class="mt-b text-center"/>
    </div>
</template>
