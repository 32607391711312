<template>
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1135_19530)">
            <mask id="mask0_1135_19530" height="24" maskUnits="userSpaceOnUse" style="mask-type:luminance" width="24"
                  x="0"
                  y="0">
                <path d="M23.8815 0.119141H0.119141V23.8815H23.8815V0.119141Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_1135_19530)">
                <path
                    d="M0.119141 11.5251C0.119141 6.14827 0.119141 3.45987 1.7895 1.7895C3.45987 0.119141 6.14827 0.119141 11.5251 0.119141H12.4756C17.8524 0.119141 20.5408 0.119141 22.2112 1.7895C23.8815 3.45987 23.8815 6.14827 23.8815 11.5251V12.4756C23.8815 17.8524 23.8815 20.5408 22.2112 22.2112C20.5408 23.8815 17.8524 23.8815 12.4756 23.8815H11.5251C6.14827 23.8815 3.45987 23.8815 1.7895 22.2112C0.119141 20.5408 0.119141 17.8524 0.119141 12.4756V11.5251Z"
                    fill="#0077FF"/>
                <path
                    d="M12.7626 17.2378C7.34671 17.2378 4.25763 13.5249 4.12891 7.34668H6.84179C6.93089 11.8813 8.93085 13.8021 10.515 14.1982V7.34668H13.0695V11.2575C14.6339 11.0892 16.2773 9.30708 16.8317 7.34668H19.3863C18.9605 9.76253 17.1783 11.5447 15.911 12.2773C17.1783 12.8714 19.2081 14.4259 19.9804 17.2378H17.1684C16.5645 15.3566 15.0596 13.9011 13.0695 13.7031V17.2378H12.7626Z"
                    fill="white"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_1135_19530">
                <rect fill="white" height="23.7624" transform="translate(0 0.119141)" width="24"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {}
</script>
